/*
	Project : AddMustard
	Author(s) : David Charles
*/

@import "../../Utilities/sassVariables";

@media only screen and (max-width: $screenBreakMax) {
    .Button {
        p {
            cursor: pointer;
        }

        a {
            cursor: pointer;

            &.hasIcon {
                display: grid;
                grid-template-columns: 30px auto;
                grid-gap: 10px;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .TiltButton {

    }
    
    .ToTopButton {
        position: fixed;
        width: 50px;
        height: 50px;
        bottom: 30px;
        right: 0;
        cursor: pointer;
        transition: bottom 100ms ease;

        .tilt{
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.5;
            transition: opacity 200ms ease;
        }

        >.inner {
            height: 50px;
            display: grid;
            grid-template-columns: 1fr;
            align-content: center;
            justify-content: center;
            align-items: center;

            .toTop {
                width: 20px;
                height: 0px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $clrBlack;
                border-radius: 3px;
            }
        }

        &:hover {
            .tilt {
                opacity: 0.75;
            }
        }
    }
}