/*
	Project : AddMustard
	Author(s) : David Charles
*/

@import "../../Utilities/sassVariables";

@media only screen and (max-width: $screenBreakMax) {
    .BlogCard {
        height: 100%;

        >.inner {
            height: 100%;

            .contentContainer {
                padding: 30px;
                height: 100%;
                grid-gap: 30px;

                .tiltContainer {
                    .tilt {
                        height: 100%;
                        top: -0%;
                    }
                }

                >.inner {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                    justify-content: start;
                    align-content: center;
                    align-items: center;

                    h3 {
                        font-family: $fntDisplay;
                        font-size: 35px;
                        line-height: 45px;
                        letter-spacing: 1px;
                        color: $clrWhite;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;  
                        overflow: hidden;
                    }

                    p {
                        color: $clrWhite;
                        text-align: left;
                    }

                    .tagContainer {
                        >.inner {
                            text-align: left;

                            .tag {
                                display: inline-block;
                                width: auto;
                                vertical-align: middle;
                                margin-right: 10px;
                                margin-bottom: 5px;

                                >.inner {
                                    display: inline-block;
                                    width: auto;

                                    .Button {
                                        >.outer {
                                            .tilt {
                                                height: 100%;
                                                width: 100%;
                                                top: 0;
                                                left: 0;
                                            }

                                            >.inner {
                                                a, p {
                                                    color: $clrBlack;
                                                    font-size: 14px;
                                                    line-height: 30px;
                                                    font-weight: 600;
                                                    padding: 0 10px;

                                                    span {
                                                        text-transform: capitalize;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            >.Button {  
                position:absolute;
                left:0;
                bottom: 0;

                >.outer {  
                    display: inline-flex;
                    width: auto;
                    transform: translateY(75%);

                    >.inner {
                        display: inline-block;
                        width: auto;

                        p {
                            color: $clrBlack;
                            font-weight: 600;
                            line-height: 30px;
                        }

                        a {
                            color: $clrBlack;
                            font-weight: 600;
                            line-height: 30px;
                        }

                    }
                }
            }

            &.withImage {
                .contentContainer {
                    height: auto;

                    .tiltContainer {
                        .tilt {
                            height: 105%;
                        }
                    }

                    >.inner {
                        h3 {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                        }
                    }
                }

                .ImageContainer {
                    z-index: 1;
                    max-height: 200px;

                    >.inner {

                    }
                }
            }
        }
    }

    .TestimonialCard {
        >.inner {
            > .tiltCotnainer {
                .tilt {
                    width: 110%;
                    left: -5%;

                    >.inner {                    
                        border: 1px solid $clrMustard;
                    }
                }
            }

            .backgroundContainer {
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;

                >.inner {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-areas:
                        "topLeft topRight"
                        "bottomLeft bottomRight";
                    height: 100%;
                    align-content: space-between;
                    justify-content: center;
                    align-items: center;

                    >.ImageContainer:nth-child(1) {
                        grid-area: topLeft;			
                        justify-content: start;
                    }

                    >.ImageContainer:nth-child(2) {
                        grid-area: bottomRight;	
                        justify-content: end;
                    }
                }
            }

            .contentContainer {
                padding: 50px;

                >.inner {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 20px;
                    align-content: center;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        font-size:40px;
                        line-height: 50px;
                        letter-spacing: 1px;
                        font-weight: 600;
                    }
                }
            }
            .cardAuthorContainer {
                display: grid;
                position: absolute;
                grid-template-columns: 1fr;
                align-content: center;
                justify-content: center;
                align-items: center;
                left: 0;
                bottom: 0;

                >.inner {
                    display: inline-block;
                    width: auto;
                    max-width: 80%;
                    transform: translateY(100%);

                    p {
                        text-align: center;
                        padding: 10px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .AboutCard {
        height: 100%;
        min-height: 100vh;
        display: grid;
        align-content: center;
        justify-content: center;
        align-items: center;
        
        >.inner {
            max-width: 1100px;
            width: 90%;
            display: grid;
            grid-template-columns: 500px 1fr;
            align-content: center;
            justify-content: center;
            align-items: center;

            .imageParent {
                max-height: 600px;
            }

            .contentContainer {
                >.inner {
                    padding-top: 50px;

                    .title {
                        display: inline-block;
                        width: auto;
                        position: absolute;
                        top: -25px;
                        left:0;

                        >.inner {
                            transform: translateY(-50%);

                            h2 {
                                font-size: 90px;
                                line-height: 100px;
                                color: $clrWhite;
                                font-family: $fntDisplay;
                                padding: 0px 20px;
                            }
                        }
                    }

                    .copy {
                        >.inner {
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-gap: 0px;
                            align-content: center;
                            justify-content: start;
                            align-items: center;

                            P {
                                text-align: left;
                            }

                            >.Button {
                                display: inline-block;
                                width: auto;
                                margin-left: 0;

                                .outer {
                                    .inner {
                                        a {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .SlideCarouselBlogCard {
        height: 100%;

        >.inner {
            height: 100%;

            .contentContainer {
                height: 100%;

                .imageParent{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;

                    .logoContainer {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 3;
                        padding: 30px 50px;

                        .ImageContainer {
                            justify-content: start;
                        }
                    }
                }

                >.inner {
                    position: absolute;
                    bottom:0;
                    left:0;

                    .tilt {
                        width: 105%;
                        height: 105%;
                        top: -2.5%;
                        left: -2.5%;
                    }

                    P {
                        color: $clrWhite;
                        font-size: 45px;
                        line-height: 55px;
                        font-family: $fntDisplay;
                        letter-spacing: 0.5px;
                        padding: 30px;

                        >span {
                            color: $clrBlack;
                            font-size: inherit;
                            line-height: inherit;
                            font-family: inherit;
                            letter-spacing: inherit;
                            margin: 0 10px;
                            padding: 0 10px;

                            &.tilt {
                                position: relative;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }
            }

            &.withImage {

            }
        }
    }
}

@media (max-width : $laptopLarge) {

}

@media (max-width : $laptopSmall) {

}

@media (max-width : $tablet) {
	
}

@media (max-width : $mobileLarge) {
    .TestimonialCard {
        >.inner {
            > .tiltContainer {
                .tilt {
                    width: 100%;
                    left: -0%;
                    height: 110%;
                    top: -5%;
                }
            }

            .backgroundContainer {
                >.inner {
                    padding: 20px;

                    >.ImageContainer {
                        img {
                            height: 60px;
                        }
                    }
                }
            }

            .contentContainer {
                >.inner {
                    h3 {
                        text-align: center;
                    }

                    p {
                        text-align: center;
                    }
                }
            }

            .cardAuthorContainer {
                >.inner {
                    max-width: 300px;
                    transform: translateY(75%);
                }
            }
        }
    }
}

@media (max-width : $mobileMedium) {
	
}

@media (max-width : $mobileSmall) {
	
}

// @media only screen and (max-width: $screenBreakDesktopSmall) {
//     .BlogCard {
//         >.inner {
//             .contentContainer {
//                 padding: 20px;

//                 >.inner {
//                     h3 {
//                         font-size: 30px;
//                         line-height: 40px;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: $screenBreakTablet) {
//     .BlogCard {
//         >.inner {
//             .contentContainer {
//                 padding: 20px;

//                 >.inner {
//                     h3 {
//                         font-size: 25px;
//                         line-height: 35px;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: $screenBreakTabletSmall) {
//     .BlogCard {
//         >.inner {
//             .contentContainer {
//                 padding: 40px;
//             }
//         }
//     }

//     .SlideCarouselBlogCard {
//         >.inner {
//             .contentContainer {
//                 >.inner {
//                     p {
//                         font-size: 30px;
//                         line-height: 40px;
//                     }
//                 }
//             }
//         }
//     }

//     .TestimonialCard {
//         >.inner {
//             .backgroundContainer {
//                 >.inner {
//                     .ImageContainer {
//                         >.inner {
//                             height: 50px;
                            
//                         }
//                     }
//                 }
//             }

//             .contentContainer {
//                 padding: 30px;

//                 >.inner {
//                     h3 {
//                         font-size: 25px;
//                         line-height: 35px;
//                     }
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width: $screenBreakMobile) {
//     .BlogCard {
//         >.inner {
            
//         }
//     }
// }

// @media only screen and (max-width: $screenBreakMobileSmall) {
//     .BlogCard {
//         >.inner {
            
//         }
//     }
// }