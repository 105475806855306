/*
	Project : AddMustard
	Author(s) : David Charles
*/

@import "./Utilities/sassVariables";

//reset all
*, ::after, ::before {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	display: block;
	position: relative;
	box-sizing: border-box;
	font-size: 0;
	font-family: $fntDefault;
	font-weight: 400;
	color: $clrBlack;
	z-index: 2;
	border: none;
	outline: none;
	text-decoration: none;
}

//default background
body {
	background-color: $clrMustard;
	min-height: 100vh;
}

//default fonts
h1, h2, h3, h4, h5, h6 {
	font-family: $fntDisplay;
	letter-spacing: 1px;
	font-weight: 600;
}
h5, h6 {
	font-weight: 400;
}
h1, h2 {
	font-size: 90px;
	line-height: 100px;
}
h3 {
	font-size: 45px;
	line-height: 55px;
}
h4 {
	font-size: 30px;
	line-height: 40px;
}
h5 {
	font-size: 25px;
	line-height: 35px;
}
h6 {
	font-size: 20px;
	line-height: 30px;
}
.p-large, .p-medium, p, a, label, input, .p-small {
	font-family: $fntDefault;
	letter-spacing: 0.5px;
	font-weight: 400;
}
.p-large {
	font-size: 25px;
	line-height: 35px;
}
.p-medium, p, a, label, input {
	font-size: 20px;
	line-height: 30px;

}
.p-small {
	font-size: 15px;
	line-height: 25px;
}
span {
	font-size: inherit;
	line-height: inherit;
	letter-spacing: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	cursor: inherit;				
	display: inline;
	width: auto;
	text-decoration: inherit;
}

@media (max-width : $laptopLarge) {

}

@media (max-width : $laptopSmall) {

}

@media (max-width : $tablet) {
	
}

@media (max-width : $mobileLarge) {
	
}

@media (max-width : $mobileMedium) {
	
}

@media (max-width : $mobileSmall) {
	
}